

















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
  private get socialNetworkButtons(): Record<string, string>[] {
    return [
      { icon: 'mdi-facebook', link: 'https://www.facebook.com/tatjana.viiiiiii/', colorClass: 'facebookColor' },
      { icon: 'mdi-instagram', link: 'https://www.instagram.com/taviatellier_/', colorClass: 'instagramColor' }
    ];
  }

  private goToLink(link: string): void {
    window.open(link, '_blank')!.focus();
  }
}
