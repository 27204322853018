




















import { Component, Mixins } from 'vue-property-decorator';
import TelephoneBtn from '@/components/TelephoneBtn.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';
import MobileDrawer from '@/components/MobileDrawer.vue';
import IsMobile from '@/mixins/IsMobile';

@Component({
  components: {
    TelephoneBtn,
    Footer,
    NavBar,
    MobileDrawer
  }
})
export default class App extends Mixins(IsMobile) {
  private drawer = false;
}
