




















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TelephoneBtn extends Vue {
  private buttons = [
    { picture: 'phone.svg', initCall: () => this.initPhoneCall(), class: 'phoneBtn' },
    { picture: 'viber.svg', initCall: () => this.initViberChat(), class: 'viberBtn' }
  ];

  private initPhoneCall(): void {
    location.href = 'tel:+37253755945';
  }

  private initViberChat(): void {
    location.href = 'viber://chat?number=%2B37253623105';
  }
}
