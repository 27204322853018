









































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/PageHeader.vue';
import { translation } from '@/plugins/Translation';

@Component({
  components: { PageHeader },
  metaInfo() {
    return {
      title: `${this.$t('name')} | ${this.$t('page.gallery.name')}`,
      htmlAttrs: {
        lang: translation.currentLocale
      },
      meta: [{ name: 'description', content: `${this.$t('page.gallery.description')}` }]
    };
  }
})
export default class Gallery extends Vue {
  private fullScreenOverlay = false;
  private selectedCarouselItem = 1;
  private galleryImages: any[] = [];

  mounted(): void {
    window.addEventListener('resize', this.appHeight);
    this.appHeight();

    const images = require.context('@/assets/gallery').keys()
      .map(path => require('@/assets/gallery' + path.substr(1)));

    this.galleryImages.push(...images);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.appHeight);
  }

  private appHeight(): void {
    const doc = document.documentElement;

    doc.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
  }

  private openFullscreenImg(selectedItem: number): void {
    this.selectedCarouselItem = selectedItem;
    setTimeout(() => {
      this.fullScreenOverlay = true;
    }, 100);
  }

  @Watch('fullScreenOverlay')
  private onFullScreenChange(newVal: boolean): void {
    const header = document.getElementsByTagName('header').item(0)!;

    if (newVal) {
      header.style.filter = 'blur(10px)';
    } else {
      header.style.filter = 'none';
    }
  }
}
