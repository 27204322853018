




































































































































import { Component, Mixins } from 'vue-property-decorator';
import type { DirectiveBinding } from 'vue/types/options';
import { translation } from '@/plugins/Translation';
import IsMobile from '@/mixins/IsMobile';
import WeOfferCard from '@/components/WeOfferCard.vue';

@Component({
  components: { WeOfferCard },
  directives: {
    'animation-class': (el: Element, binding: DirectiveBinding) =>
      el.setAttribute('animation-class', binding.value)
  },
  metaInfo() {
    return {
      title: `${this.$t('name')} | ${this.$t('page.main.name')}`,
      htmlAttrs: {
        lang: translation.currentLocale
      },
      meta: [{ name: 'description', content: `${this.$t('page.main.description')}` }]
    };
  }
})
export default class MainPage extends Mixins(IsMobile) {
  private readonly chooseUsIntersectionObserver =
    new IntersectionObserver(this.chooseUsIntersectionHandler, { threshold: 0.4 });
  private readonly hideNavbarIntersectionObserver =
    new IntersectionObserver(this.hideNavbarIntersectionHandler, { threshold: 0.6 });

  private readonly headerEl = document.getElementsByTagName('header').item(0);

  private chooseUsBlockData = [
    {
      title: 'mainPage.chooseUs.block1.title',
      description: 'mainPage.chooseUs.block1.description',
      icon: 'mdi-star-outline',
      animationClass: 'slideRightFade',
      mobileAnimationClass: 'slideRightFade'
    },
    {
      title: 'mainPage.chooseUs.block2.title',
      description: 'mainPage.chooseUs.block2.description',
      icon: 'mdi-briefcase-outline',
      animationClass: 'slideTopFade',
      mobileAnimationClass: 'slideLeftFade'
    },
    {
      title: 'mainPage.chooseUs.block3.title',
      description: 'mainPage.chooseUs.block3.description',
      icon: 'mdi-hand-heart-outline',
      animationClass: 'slideLeftFade',
      mobileAnimationClass: 'slideRightFade'
    }
  ];

  private weOfferBlockData = [
    {
      text: 'mainPage.weOffer.block1.text',
      header: 'mainPage.weOffer.block1.header',
      imageName: 'we-offer-2.jpg',
      isReversed: false
    },
    {
      text: 'mainPage.weOffer.block2.text',
      header: 'mainPage.weOffer.block2.header',
      imageName: 'we-offer-1.jpg',
      isReversed: true
    }
  ];

  created(): void {
    this.headerEl!.style.transitionProperty = 'none';
    this.hideHeader();
  }

  mounted(): void {
    document.querySelectorAll('#choose-us-block')
      .forEach((el: Element) => this.chooseUsIntersectionObserver.observe(el));
    this.hideNavbarIntersectionObserver.observe(document.getElementById('full-screen-image') as Element);
  }

  private get mainImgDynamicSize(): any {
    if (this.$vuetify.breakpoint.xlOnly) {
      return require('@/assets/mainpage/main-page-img-4k.jpg');
    } else if (this.$vuetify.breakpoint.xsOnly) {
      return require('@/assets/mainpage/main-page-img-mobile.jpg');
    }

    return require('@/assets/mainpage/main-page-img.jpg');
  }

  beforeDestroy(): void {
    this.chooseUsIntersectionObserver.disconnect();
    this.hideNavbarIntersectionObserver.disconnect();
  }

  private hideHeader(): void {
    this.headerEl!.style.transform = 'translateY(-130px)';
  }

  private showHeader(): void {
    this.headerEl!.style.transitionProperty = 'inherit';
    this.headerEl!.style.transform = 'translateY(0)';
  }

  private chooseUsIntersectionHandler(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.4) {
        (entry.target as HTMLElement).childNodes.forEach((child) => {
          const childEl = child as HTMLElement;

          childEl.classList.add(childEl.getAttribute('animation-class') || '');
        });
        observer.unobserve(entry.target);
      }
    });
  }

  private hideNavbarIntersectionHandler(entries: IntersectionObserverEntry[]): void {
    entries[0].intersectionRatio >= 0.6 ? this.hideHeader() : this.showHeader();
  }

  private scrollAwayFromMainScreen(): void {
    const imgHeight = document.getElementById('full-screen-image')!.getBoundingClientRect().height;
    const scrollFor = imgHeight - window.scrollY - (this.isMobile ? 50 : 130);

    window.scrollBy({ top: scrollFor, behavior: 'smooth' });
  }
}
