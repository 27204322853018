var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",class:{ 'blurBackground': _vm.fullScreenOverlay },attrs:{"fluid":""}},[_c('PageHeader',{attrs:{"header-text":_vm.$t('headers.gallery')}}),_c('v-row',{staticStyle:{"padding":"5vw 7vw !important"}},_vm._l((_vm.galleryImages),function(img,i){return _c('v-col',{key:'gallery-img-' + i,staticClass:"d-flex child-flex",attrs:{"cols":"20","lg":"3","md":"4","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":img,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{staticClass:"magnifying-img-btn",staticStyle:{"background-color":"transparent !important"},on:{"click":function($event){return _vm.openFullscreenImg(i)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-magnify-plus ")])],1)],1):_vm._e()],1):_c('v-btn',{staticClass:"magnifying-img-btn",staticStyle:{"background-color":"transparent !important"},on:{"click":function($event){return _vm.openFullscreenImg(i)}}})],1)]}}],null,true)})],1)}),1),_c('v-dialog',{attrs:{"fullscreen":"","transition":false},model:{value:(_vm.fullScreenOverlay),callback:function ($$v) {_vm.fullScreenOverlay=$$v},expression:"fullScreenOverlay"}},[_c('v-container',{staticClass:"fill-height",staticStyle:{"background-color":"#000000c2"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"full-screen",attrs:{"justify":"center","align":"center"}},[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows-on-hover":"","show-arrows":_vm.$vuetify.breakpoint.mdAndUp},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","ripple":false,"color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","size":"30"}},[_vm._v(" mdi-arrow-left ")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","ripple":false,"color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","size":"30"}},[_vm._v(" mdi-arrow-right ")])],1)]}}]),model:{value:(_vm.selectedCarouselItem),callback:function ($$v) {_vm.selectedCarouselItem=$$v},expression:"selectedCarouselItem"}},_vm._l((_vm.galleryImages),function(img,i){return _c('v-carousel-item',{key:'carousel-item-' + i,attrs:{"transition":false}},[_c('v-img',{staticClass:"full-screen",attrs:{"src":img,"contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1)]},proxy:true}],null,true)})],1)}),1)],1)],1),_c('v-btn',{staticStyle:{"background":"white !important"},attrs:{"dark":"","fixed":"","height":"50","width":"50","color":"black","ripple":false,"icon":"","large":"","right":"","top":""},on:{"click":function($event){_vm.fullScreenOverlay = false}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }