


































































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/PageHeader.vue';
import { translation } from '@/plugins/Translation';
import prices from '@/locale/prices.json';

interface PanelItem {
  name: string;
  priceFrom: number;
  priceTo: number;
}

interface Panel {
  name: string;
  items: PanelItem[];
}

@Component({
  components: { PageHeader },
  metaInfo() {
    return {
      title: `${this.$t('name')} | ${this.$t('page.prices.name')}`,
      htmlAttrs: {
        lang: translation.currentLocale
      },
      meta: [{ name: 'description', content: `${this.$t('page.prices.description')}` }]
    };
  }
})
export default class Prices extends Vue {
  private panelData: Record<string, Panel[]> = {};

  mounted(): void {
    this.setTranslatedPrices();
  }

  private setTranslatedPrices() {
    this.panelData = prices[translation.currentLocale];
  }

  @Watch('$route.params.locale')
  private onLocaleChange(): void {
    this.setTranslatedPrices();
  }
}
