






































































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import SwitchLanguageBtn from '@/components/SwitchLanguageBtn.vue';

@Component({
  components: { SwitchLanguageBtn }
})
export default class NavBar extends Vue {
  @PropSync('drawer', { type: Boolean })
  private syncedDrawer!: boolean;
  @PropSync('isMobile', { type: Boolean })
  private syncedIsMobile!: boolean;

  private isMainPage = false;

  private hideSlider = true;
  private readonly tabs = [
    { translationTag: 'page.main.name', route: 'root', icon: 'mdi-home-city' },
    { translationTag: 'page.gallery.name', route: 'gallery', icon: 'mdi-image-multiple' },
    { translationTag: 'page.prices.name', route: 'prices', icon: 'mdi-currency-eur' },
    { translationTag: 'page.contacts.name', route: 'contacts', icon: 'mdi-contacts' }
  ];

  @Watch('$route.name', { immediate: true, deep: true })
  private onRouteChange(routeName: string): void {
    this.isMainPage = routeName === 'root';
  }

  private showSlider() {
    this.hideSlider = false;
  }
}
