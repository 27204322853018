


















































import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/components/PageHeader.vue';
import { translation } from '@/plugins/Translation';

@Component({
  components: {
    PageHeader
  },
  metaInfo() {
    return {
      title: `${this.$t('name')} | ${this.$t('page.contacts.name')}`,
      htmlAttrs: {
        lang: translation.currentLocale
      },
      meta: [{ name: 'description', content: `${this.$t('page.contacts.description')}` }]
    };
  }
})
export default class Contacts extends Vue {
  private get contacts(): any {
    return [
      { text: '+37253755945', icon: 'mdi-phone' },
      { text: 'tavi.ateljee@gmail.com', icon: 'mdi-email' },
      { text: this.$t('footerAddress'), icon: 'mdi-home' },
      { text: 'taviatellier_', link: 'https://www.instagram.com/taviatellier_/', icon: 'mdi-instagram' },
      { text: 'tatjana.viiiiiii', link: 'https://www.facebook.com/tatjana.viiiiiii', icon: 'mdi-facebook' }
    ];
  }

  private goToLink(link: string): void {
    window.open(link, '_blank')!.focus();
  }
}
