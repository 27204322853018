






















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class WeOfferCard extends Vue {
  @Prop({ type: String, required: true })
  private textTranslatedKey!: string;

  @Prop({ type: String, required: true })
  private headerTranslatedKey!: string;

  @Prop({ type: String, required: true })
  private imageName!: string;

  @Prop({ type: Boolean, default: false })
  private isReversed!: boolean;
}
