















































































import { Component, PropSync } from 'vue-property-decorator';
import SwitchLanguageBtn from '@/components/SwitchLanguageBtn.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: { SwitchLanguageBtn }
})
export default class MobileDrawer extends Footer {
  @PropSync('drawer', { type: Boolean })
  private syncedDrawer!: boolean;
  @PropSync('isTiltedScreen', { type: Boolean })
  private syncedTiltedScreen!: boolean;

  private langChange = false;
  private readonly tabs = [
    { translationTag: 'page.main.name', route: 'root', icon: 'mdi-home-city' },
    { translationTag: 'page.gallery.name', route: 'gallery', icon: 'mdi-image-multiple' },
    { translationTag: 'page.prices.name', route: 'prices', icon: 'mdi-currency-eur' },
    { translationTag: 'page.contacts.name', route: 'contacts', icon: 'mdi-contacts' }
  ];
}
